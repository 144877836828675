import { createStyles, makeStyles } from '@material-ui/core'

export const useMaterialClasses = makeStyles((theme) => createStyles({
    singleArticleComponent: {
        '& p>img': {
            maxWidth: '100%'
        },
        '& figure': {
            margin: 0,
            padding: 0,
            position: 'relative',
            [theme.breakpoints.down('xs')]: {
                marginLeft: -theme.spacing(2),
                marginRight: -theme.spacing(2),
            },

            '& figcaption': {
                top: '100%',
                color: theme.palette.text.secondary,
                fontSize: theme.typography.caption.fontSize,
                [theme.breakpoints.down('xs')]: {
                    marginLeft: theme.spacing(2),
                    marginRight: theme.spacing(2),
                },
                [theme.breakpoints.up('sm')]: {
                    position:'absolute',
                    width: 250,
                    right: -258,
                    transform: 'rotate(-90deg)',
                    transformOrigin: 'top left',
                },
            },
        },
        '& .credits': {
            margin: 0,
            padding: 0,
            paddingLeft: 8,
            color: theme.palette.text.secondary,
            borderLeft: `3px solid ${theme.palette.text.secondary}`,
            '& ul': {
                margin: 0,
                padding: 0,
                '& li': {
                    listStyle: 'none',
                }
            }
        }
    },
}))
