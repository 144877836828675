import { Dialog, Grid, Typography } from '@material-ui/core'
import { Link } from 'gatsby'
import React, { useMemo, useState } from 'react'
import { useMaterialClasses } from './components/hooks'

interface Props {
    title: string
    content: string
    publishedOn?: string
    readMorePath?: string
    style?: React.CSSProperties
}

export default function SingleArticle({title, content, publishedOn, readMorePath, style = {}}: Props) {
    const {singleArticleComponent} = useMaterialClasses()

    const [overlayImage, setOverlayImage] = useState<string | undefined>(undefined)
    const [overlayOpen, setOverlayOpen] = useState(false)
    
    const p = useMemo(() => {
        if(publishedOn) {
            const d = new Date(publishedOn)
            return [
                d.toLocaleString('default', {month: 'short'}),
                d.toLocaleString('default', {year: 'numeric'}),
            ]. join(' ')
        }
        return undefined
    }, [publishedOn])

    return <>
    <Grid
        container
        spacing={2}
        className={singleArticleComponent}
        style={style}
    >
        <Grid item xs={12}>
            {
                p &&
                <Typography
                    variant='button'
                    color='secondary'
                    style={{
                        fontWeight: 'bold',
                        fontSize: 'small'
                    }}
                    children={p}
                />
            }
            <Typography
                variant='h5'
                style={{fontFamily: 'Fira Sans, sans-serif'}}
                dangerouslySetInnerHTML={{ __html: title}}
            />
        </Grid>
        <Grid item xs={12}>
            <Typography
                variant='body1'
                component='div'
                dangerouslySetInnerHTML={{ __html: content}}
                onClick={(event: any)=>{
                    if(event.target.tagName === 'IMG' && event.target.src !== undefined) {
                        setOverlayImage(event.target.src)
                        setOverlayOpen(true)
                    }
                }}
            />
            {
                readMorePath &&
                <Typography
                    component={Link}
                    to={readMorePath}
                    children={'Read more'}
                    color='secondary'
                />
            }
        </Grid>
    </Grid>
    <Dialog
        open={overlayImage !== undefined && overlayOpen}
        onClose={() => setOverlayOpen(false)}
        maxWidth='xl'
        BackdropProps={{
            style:{
                background: '#000000de'
            }
        }}
        onClick={() => setOverlayOpen(false)}
        scroll='body'
        // PaperComponent='div'
    >
        <img
            src={overlayImage}
            style={{ maxWidth: '100%', maxHeight: '90vh' }}
        />
    </Dialog>
    </>
}